import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";

const Links: React.FC = () => {
    return (
        <div className="links">
            <a href="https://t.me/sherexcoin" target="_blank" rel="noopener noreferrer">
                <FaTelegramPlane size={40} />
            </a>
            <a href="https://x.com/sherexcoin" target="_blank" rel="noopener noreferrer">
                <FaXTwitter size={40} />
            </a>
        </div>
    );
};

export default Links;
