import React from 'react';
import './App.css';
import Logo from './components/Logo';
import Links from './components/Links';

function App() {
    return (
        <div className="container">
            <p className="token">$<b>SHRX</b></p>
            <Logo />
            <p className="x">The Mother of All Meme Coins</p>
            <p className="coming-soon"><b>Coming soon...</b>
                <span></span><span></span><span></span><span></span>
                <span></span><span></span><span></span>
            </p>
            <Links />
        </div>
    );
}

export default App;
